import styled from "styled-components";
import Logo from '../../assets/logo.svg'
const S = {
    Wrapper: styled.div`
    z-index: 9;
    background-color: #00A9C6;
    padding:80px;
    position:relative;
    display:flex;
    flex-direction:column;
    align-items:center;
    margin:0px 120px 100px 120px;
    border-radius:16px;
    height:300px;
    .circle{
        width:500px;
        height:500px;
        border-radius:100%;
        background-color:white;
        position:absolute;
        top: -220px;
        right: -173px;
        opacity:0.1;
        overflow:hidden;
        z-index:0;
    }
    .top{
        width:100%;    
        display:flex;
        flex-direction:row;
        justify-content: space-between;
        h1{
            // flex-basis:100%;
            font-size:3em;
            color:white;
            margin:0;
            padding:0;
            font-weight:400;
            text-align:left;
        }
        p{
            color:black;
            text-align:center;
            font-weight:300;
        }
        a{
            text-decoration:none;
            border:0px;
            background:white;
            color:#00A9C1;
            padding:15px 20px;
            border-radius:4px;
            margin-right:10px;
            font-weight:600;
            font-size:16px;
            // flex-basis:20%;
            flex-wrap:nowrap;
            z-index:10;
            transition:500ms;
            &:hover{
                transition:500ms;
              background-color:#01FF95;
              box-shadow: hsl(154.96,100%,50.2%) 0px 8px 24px;
              color:white;
              .tele{
                transition:500ms;
                filter: brightness(500%);
              }
            }
            .tele{
                transition:500ms;
              width:16px;
              vertical-align:text-top;
              padding-left:10px;
            }
          }
    }
    
    `,
    ImageWrapper: styled.div`
    position:absolute;
    top:200px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    // flex-basis:50%;
    flex-wrap:wrap;
    width:100%;
    .contain{
        padding:10px;
        background:white;
        border-radius:8px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        margin:10px;
        display:flex;
        flex-direction:column;
        text-align:center;
        flex-basis:20%;
        align-items:center;
        transition:500ms;
        justify-content:center;
        cursor: pointer;
        img{
            width:100px;
        }
        h3,p{
            margin:10px 0;
            font-weight:300;
            padding:0;
        }
        &:hover{
            background-color:#FCBA96;
            transition:500ms;
            margin:5px;
            box-shadow: hsl(21.18,94.44%,78.82%) 0px 8px 24px;
            h3{
                margin:10px 0;
                font-weight:700;
                color:white;
            }
        }
    }
    
    `
}

export default S