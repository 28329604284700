import styled from "styled-components";
import Logo from '../../assets/logo.svg'
const S = {
    Wrapper: styled.div`
    z-index: 9;
    background-color: #F3F4F7;
    padding:80px;
    position:relative;
    display:flex;
    flex-direction:row;
    align-items:center;
    margin:0px 120px 100px 120px;
    border-radius:16px;
    // height:300px;
    justify-content: space-between;
    background-color: #e5e5f7;
    opacity: 0.8;
    background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #e5e5f7 10px ), repeating-linear-gradient( #00a8c655, #00a8c6 );
   
.circle{
        width:500px;
        height:500px;
        border-radius:100%;
        background-color:white;
        position:absolute;
        top: -220px;
        right: -173px;
        opacity:0.1;
        overflow:hidden;
        z-index:0;
    }
    .top{
        width:100%;    
        display:flex;
        flex-direction:column;
        
        h1{
            flex-basis:100%;
            font-size:5em;
            color:black;
            margin:0;
            padding:0;
            font-weight:400;
            text-align:left;
        }
        p{
            // color:#A1432B;
            text-align:left;
            font-weight:300;
        }
        button{
            border:0px;
            background:white;
            color:#00A9C1;
            padding:15px 20px;
            border-radius:4px;
            margin-right:10px;
            font-weight:600;
            font-size:16px;
            flex-basis:20%;
            z-index:10;
            transition:500ms;
            &:hover{
                transition:500ms;
              background-color:#01FF95;
              box-shadow: hsl(154.96,100%,50.2%) 0px 8px 24px;
              color:white;
              .tele{
                transition:500ms;
                filter: brightness(500%);
              }
            }
            .tele{
                transition:500ms;
              width:16px;
              vertical-align:text-top;
              padding-left:10px;
            }
          }
    }
    
    `,
    ImageWrapper: styled.div`
    // position:absolute;
    top:200px;
    display:flex;
    flex-direction:row;
    justify-content:left;
    // flex-basis:50%;
    flex-wrap:wrap;
    width:100%;
    
    .contain{
        padding:20px 30px;
        background:white;
        border-radius:8px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        // margin:10px;
        display:flex;
        flex-direction:row;
        text-align:left;
        // flex-basis:50%;
        align-items:left;
        transition:500ms;
        justify-content:center;
        cursor: pointer;
        img{
            width:50px;
            margin-right:20px;
        }
        h3{
            margin:0px 0px;
            font-weight:300;
            padding:0;
            color:#00A8C4;
        }
        p{
            margin:0px 0;
            font-weight:600;
            padding:0;
            font-size:20px;
        }
       
    }
    
    `
}

export default S