import styled from 'styled-components';

export const LangChangerSC = styled.select`
          border: none;
         margin:0px;
         padding:0px;
         background: #ffffff00;
         font-size:1.5em;
         option{
          background:none;
          font-size:20px;
         }
         img{
          width:20px;
          height:20px;
         }
        @media screen and (max-width:1000px){
       /* display:none; */
      }
        cursor:pointer;
          option {
  
            /* padding: 10px; */
          }
          .options {
            /* padding: 10px; */
          }
          &:hover {
            border: none;
          }
          &:focus {
            border: none;
            outline: none;
          }
`