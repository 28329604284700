import React, { useState } from 'react';
import { Link,useLocation } from 'react-router-dom'
import S from './style'
import Logo from '../../assets/logo.svg'
import { Category, Close, Menu } from '@mui/icons-material'
import data from '../../services/data';
import Translate from '../../locales/Translate'
import TranslateStatic from '../../locales/TranslateStatic'
import LangChanger from './LangChanger'
function Header(props) {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const [result, setResult] = useState(false);
    const [searchPanel, setSearchPanel] = useState(false);
    const { pathname } = useLocation();
    console.log(pathname)
    function flattenObject(ob) {
        let arr = [];
        ob.map((x, i) => {
            if (x.category.link) {
                arr.push({ 'link': x.category.link })
            }
            if (x.category.subcategories) {
                x.category.subcategories.map((j, s) => {
                    arr.push({ 'link': j.link, 'name': j.name })
                })
            }
        })
        return arr
        // var toReturn = {};

        // for (var i in ob) {
        //     if (!ob.hasOwnProperty(i)) continue;

        //     if ((typeof ob[i]) == 'object' && ob[i] !== null) {
        //         var flatObject = flattenObject(ob[i]);
        //         for (var x in flatObject) {
        //             if (!flatObject.hasOwnProperty(x)) continue;

        //             toReturn[i + '.' + x] = flatObject[x];
        //         }
        //     } else {
        //         toReturn[i] = ob[i];
        //     }
        // }
        // return toReturn;
    }
    function ara(value) {
        // var res = search(data, function (key, val) { // im looking for this key value pair
        //     return key === 'name' && val === value;
        //     // return val === value;
        // });
        console.log(value)
        let res = flattenObject(data);
        let res2 = res.filter(element => {
            if (element.link) {
                console.log(`/${value}/g`)
                if (element.link.match(`${value}`)) {
                    // do something
                    console.log(element)
                    return element
                }

            }
        });
        console.log(res2)
        if (res2.length > 0) {

            setSearchPanel(true)
        } else {
            setSearchPanel(false)
        }
        setResult(res2)
    }
    return (
        <S.Wrapper fontColor={pathname === '/' ? true : false }>
            <S.Logo src={Logo} alt="dogantan logo" />
            <S.Nav click={click} fontColor={pathname === '/' ? true : false }>
                <li onClick={closeMobileMenu}><Link to="/"> <TranslateStatic string="home" /></Link></li>
                <li onClick={closeMobileMenu}><Link to="/team"> <TranslateStatic string="products" /></Link></li>
                {/* <li onClick={closeMobileMenu}><Link to="/aboutus">KURUMSAL</Link></li> */}

                <li onClick={closeMobileMenu} id="dropdown">
                    <TranslateStatic string="institutional" />
                    <div class="dropdown-content">
                        <div className='dropdown-in'>

                        <Link to="/yumurtlama"><TranslateStatic string="yumurtlamanın uyarılması" /></Link>
                        {/* <Link to="/certifications"><TranslateStatic string="certificates" /></Link> */}
                        <Link to="/genetiktarama"><TranslateStatic string="genetik tarama" /></Link>
                        <Link to="/asilama"><TranslateStatic string="aşılama" /></Link>
                        <Link to="/donduraraksaklama"><TranslateStatic string="dondurarak saklama" /></Link>
                        <Link to="/endoskopikameliyatlar"><TranslateStatic string="endoskopik ameliyatlar" /></Link>
                        <Link to="/tesa"><TranslateStatic string="TESA / MIKRO TESE" /></Link>
                        <Link to="/tupbebek"><TranslateStatic string="tüp bebek tedavisi" /></Link>
                        </div>
                    </div>
                </li>
                <li onClick={closeMobileMenu}><Link to="/category"><TranslateStatic string="services" /></Link></li>
                {/* <li onClick={closeMobileMenu}><Link to="/references"><TranslateStatic string="referances" /></Link></li> */}
                <li onClick={closeMobileMenu}><Link to="/contactus"><TranslateStatic string="contact" /></Link></li>
            </S.Nav>
            <S.Search>
              
                <LangChanger lang={props.lang} changeLanguage={props.changeLanguage} />
                {/* <input placeholder="⌕" type="text" onChange={(e) => ara(e.target.value)}
                onClick={(e) => { if (e.target.value) { setSearchPanel(true) } }} /> */}
            
            </S.Search>
            {/* <S.Search>
                <li onClick={closeMobileMenu}><Link to="/offer"><TranslateStatic string="teklif" /></Link></li>
            </S.Search> */}
            <S.MobileMenu onClick={handleClick}>
                {click ? (
                    <Close className="menu-icon" />
                ) : (
                    <Menu className="menu-icon" />
                )}
            </S.MobileMenu>
        </S.Wrapper>
    );
}

export default Header;