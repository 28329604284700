import styled from "styled-components";
import Logo from '../../assets/logo.svg'
const S = {
    Wrapper: styled.div`
    z-index: 100;
    // background-color: #00A9C6;
    padding:40px;
    position:fixed;
    right:0px;
    bottom:0px;
    display:flex;
    flex-direction:column;
    align-items:center;

    h4{
        flex-basis:100%;
        font-size:2em;
        color:black;
        margin:0;
        padding:0;
        font-weight:400;
        text-align:center;
    }
    p{
        color:black;
        text-align:center;
        font-weight:300;
    }
    `,
    ImageWrapper: styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    flex-basis:50%;
    img{
        width:50px;
        animation: heartbeat 1s infinite;
        @keyframes heartbeat
        {
        0%
        {
            transform: scale( .75 );
        }
        20%
        {
            transform: scale( 1 );
        }
        40%
        {
            transform: scale( .75 );
        }
        60%
        {
            transform: scale( 1 );
        }
        80%
        {
            transform: scale( .75 );
        }
        100%
        {
            transform: scale( .75 );
        }
        }
    }

    `
}

export default S