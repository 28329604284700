
import { Link } from 'react-router-dom';
import S from './style'
// import Image1 from '../../assets/sertification/tse.svg'
import Certification from '../../components/certification';
import { ReactComponent as Tse } from '../../assets/sertification/tsechoose.svg'
import { ReactComponent as Ce } from '../../assets/sertification/ce.svg'
import Iso from '../../assets/sertification/isopng.png'
import TranslateStatic from '../../locales/TranslateStatic';
import Accordion from './accordion'
import Mete from '../../assets/ekip/mete.jpg'
import Batu from '../../assets/ekip/batu.jpg'
import Adil from '../../assets/ekip/adil.png'
import Suat from '../../assets/ekip/suat.png'
import Ayse from '../../assets/ekip/aysekendirci.png'
import Aysenur from '../../assets/ekip/aysenururfan.png'
import Gamze from '../../assets/ekip/gamze.png'
import Amilya from '../../assets/ekip/amilya.jpg'
import Senem from '../../assets/ekip/senem.jpeg'
import Kifayet from '../../assets/ekip/MAHARRAMOVA.jpg'
import Oznur from '../../assets/ekip/oznursahin.png'
import Yeliz from '../../assets/ekip/yelizkeskin.png'
import Rabia from '../../assets/ekip/rabia.png'
import Suzan from '../../assets/ekip/suzanna.jpg'
import Ozlem from '../../assets/ekip/ozlem.jpeg'
import Semra from '../../assets/ekip/semra.jpeg'
import Safinur from '../../assets/ekip/safinur.png'
import Hulya from '../../assets/ekip/hulya.png'
import Levent from '../../assets/ekip/levent.png'

export function Team() {
  return (
    <S.Wrapper>
      {/* <S.Circle/> */}
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="teamHeader" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <TranslateStatic string="teamHeader" />
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
       <Certification/>
      </S.Sertifika> */}
      <S.Section>
      <S.Baslik><TranslateStatic string="teamHeader" /></S.Baslik>
        <S.Izgara>
          <div className='rowi'>
            <div className='items'>
              <img src={Mete}></img>
              <h2>Doç. Dr. Mete Işıkoğlu</h2>
              <div className='content'>
                <p>Mes'ul Müdür</p>
                <article>Kadın Hastalıkları ve Doğum Uzmanı</article>
                <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article>
              </div>

            </div>
          </div>
          <div className='rowi'>
            <div className='items'>
              <img src={Batu}></img>
              <h2>Op. Dr. Batu Aydınuraz</h2>
              <div className='content'>
                {/* <p>Mes'ul Müdür</p> */}
                <p>Kadın Hastalıkları ve Doğum Uzmanı</p>
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}
              </div>

            </div>
            <div className='items'>
              <img src={Adil}></img>
              <h2>Prof. Dr. Adil Gökalp</h2>
              <div className='content'>
                {/* <p>Mes'ul Müdür</p> */}
                <p>Üroloji Uzmanı</p>

              </div>

            </div>
            <div className='items'>
              <img src={Suat}></img>
              <h2>Uzm. Dr. Suat Çulhaöz</h2>
              <div className='content'>
                {/* <p>Mes'ul Müdür</p> */}
                <p>Anestezi ve Reanimasyon Uzmanı</p>

              </div>

            </div>
          </div>
          <div className='rowi'>
            <div className='items'>
              <img src={Ayse}></img>
              <h2>Dr. Ayşe Kendirci Çeviren</h2>
              <div className='content'>
                <p>Embriyolog</p>
                {/* <article>Kadın Hastalıkları ve Doğum Uzmanı</article> */}
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}
              </div>

            </div>
            <div className='items'>
              <img src={Aysenur}></img>
              <h2>Ayşenur Avcı</h2>
              <div className='content'>
                <p>Biyolog</p>
                {/* <article>Üroloji Uzmanı</article> */}

              </div>

            </div>
            <div className='items'>
              <img src={Gamze}></img>
              <h2>Gamze Yılmaz</h2>
              <div className='content'>
                <p>Lab. Teknikeri</p>
                {/* <article>Lab. Teknikeri</article> */}

              </div>

            </div>
          </div>
          <div className='rowi'>
            <div className='items'>
              <img src={Amilya}></img>
              <h2>Amilya Kibar</h2>
              <div className='content'>
                <p>Tedavi Koordinatörü</p>
                {/* <article>Kadın Hastalıkları ve Doğum Uzmanı</article> */}
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}
              </div>

            </div>
            <div className='items'>
              <img src={Senem}></img>
              <h2>Senem Aslanbaş</h2>
              <div className='content'>
                <p>Tedavi Koordinatörü</p>
                {/* <article>Üroloji Uzmanı</article> */}

              </div>

            </div>
            <div className='items'>
              <img src={Kifayet}></img>
              <h2>Kifayet Maharramova</h2>
              <div className='content'>
                <p>Tedavi Koordinatörü</p>
                {/* <article>Lab. Teknikeri</article> */}

              </div>

            </div>
          </div>
          <div className='rowi'>
            <div className='items'>
              <img src={Yeliz}></img>
              <h2>Yeliz Gelen</h2>
              <div className='content'>
                <p>İdari Birim</p>
                {/* <article>Kadın Hastalıkları ve Doğum Uzmanı</article> */}
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}
              </div>

            </div>
            <div className='items'>
              <img src={Oznur}></img>
              <h2>Öznur Şahin Kotanak</h2>
              <div className='content'>
                <p>İdari Birim</p>
                {/* <article>Üroloji Uzmanı</article> */}

              </div>

            </div>

          </div>
          <div className='rowi'>
            <div className='items'>
              <img src={Rabia}></img>
              <h2>Rabia Şak</h2>
              <div className='content'>
                <p>Hasta Kabul</p>
                {/* <article>Kadın Hastalıkları ve Doğum Uzmanı</article> */}
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}
              </div>

            </div>


          </div>
          <div className='rowi'>
            <div className='items'>
              <img src={Suzan}></img>
              <h2>Suzanna Sarışoğlu</h2>
              <div className='content'>
                <p>Ev İdaresi</p>
                {/* <article>Kadın Hastalıkları ve Doğum Uzmanı</article> */}
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}
              </div>

            </div>
            <div className='items'>
              <img src={Ozlem}></img>
              <h2>Özlem Demir</h2>
              <div className='content'>
                <p>Ev İdaresi</p>
                {/* <article>Üroloji Uzmanı</article> */}

              </div>

            </div>
            <div className='items'>
              <img src={Semra}></img>
              <h2>Semra Köse</h2>
              <div className='content'>
                <p>Ev İdaresi</p>
                {/* <article>Lab. Teknikeri</article> */}

              </div>

            </div>
          </div>
          <div className='rowi'>
            <div className='items'>
              <img src={Safinur}></img>
              <h2>Safinur Postacıoğlu</h2>
              <div className='content'>
                <p>Kurumsal İletişim</p>
                {/* <article>Kadın Hastalıkları ve Doğum Uzmanı</article> */}
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}
              </div>

            </div>


          </div>
          <div className='rowi'>
            <div className='items'>
              <img src={Hulya}></img>
              <h2>Hülya Kara</h2>
              <div className='content'>
                <p>Mali Müşavir</p>
                {/* <article>Kadın Hastalıkları ve Doğum Uzmanı</article> */}
                {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}
              </div>
            </div>
            
              <div className='items'>
                <img src={Levent}></img>
                <h2>Av. Levent Geçerler</h2>
                <div className='content'>
                  <p>Hukuk Müşaviri</p>
                  {/* <article>Kadın Hastalıkları ve Doğum Uzmanı</article> */}
                  {/* <article>Endoskopi Uzmanı ( Bachelor in Endoscopy )</article> */}
                </div>

              </div>
            
          </div>
        </S.Izgara>

      </S.Section>
    </S.Wrapper>
  );
}
