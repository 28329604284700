
import { Link } from 'react-router-dom';
import S from './style'
// import Image1 from '../../assets/sertification/tse.svg'
import Certification from '../../components/certification';
import { ReactComponent as Tse } from '../../assets/sertification/tsechoose.svg'
import { ReactComponent as Ce } from '../../assets/sertification/ce.svg'
import Iso from '../../assets/sertification/isopng.png'
import TranslateStatic from '../../locales/TranslateStatic';
import YumurtlamaImg from '../../assets/asilama.jpeg'
export function Asilama() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <Link><TranslateStatic string="aşılama" /></Link>
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <div className='banner'>
          <div className='baslik'>
            <h1>
              <TranslateStatic string="aşılama" />
            </h1>
            {/* <h3>
            (GENETİK ve KALITSAL HASTALIKLARIN TANISI)
            </h3> */}
          </div>
          <div className='imgwrap'>

          <img className='foregroundImg' src={YumurtlamaImg} />
          <img className="backgroundImg" src={YumurtlamaImg} />
          </div>



        </div>

        <p>
          <TranslateStatic string="asilamaP1" />
        </p>
        
      </S.Section>
    </S.Wrapper >
  );
}
