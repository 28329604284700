import React, { useState, useEffect } from 'react';

import { BrowserRouter,Outlet } from 'react-router-dom';
import { createBrowserHistory } from "history";
import Header from './components/header'
import Footer from './components/footer';
import GlobalStyle from './globalStyle';
import Routi from './routes'
import CookieConsent from 'react-cookie-consent'
import { LocaleContext } from "./locale-context";
import landChecker from './utils/langChecker';
import Whatsup from './components/whatsapp';

const customHistory = createBrowserHistory();
export default () => {
  const [preferredLocale, setPreferredLocale] = useState("tr");
  function changeLanguage(event) {
    setPreferredLocale(event.target.value)
  };
  useEffect(() => {
    setPreferredLocale(landChecker);
  }, [])
  return <LocaleContext.Provider value={preferredLocale} >
    <BrowserRouter history={customHistory}>
      <GlobalStyle />
      <Whatsup/>
      <Routi />
   
      <CookieConsent
      overlay
        location="bottom"
        buttonText="Onayla"
        cookieName="Dogantanyangin Cookie"
        style={{
          background: "#D41031",
          borderRadius: '10px',
          width: '400px',
          margin: '20px',
          padding: '5px',
          color: 'white',
          zIndex: 111111111111,
          // backgroundImage: 'url(/static/media/navbg.00a811e3.svg)',
          backgroundSize: '100% 100%'
        }}
        buttonStyle={{ color: "white", borderRadius: '5px', fontSize: "13px", background: 'black', width: '100%', fontWeight: 'bold' }}
        expires={150}
      >

        Size daha iyi hizmet sunabilmek için çerezleri kullanıyoruz.
        <br></br>
        <a style={{ color: 'white' }} href="/cookie">Ayrıntılı bilgi...</a>

      </CookieConsent>
    </BrowserRouter>
  </LocaleContext.Provider>
}