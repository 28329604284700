import styled from "styled-components";

const S = {
    Wrapper: styled.section`
    width: 100%;
    height:400px;
    .imgbanner{
        display:flex;
        flex-direction:row;
        justify-content:flex-end;    
        position:relative;
        height:100%;
        .Gradient{
            width:100%;
            height:100%;
            z-index:1;
            // background: rgb(0,0,0);
            background: linear-gradient(90deg, rgba(0,0,0,1) 34%, rgba(254,254,254,0) 72%);
            color:white;
            padding:50px;
            box-sizing:border-box;
            h1{
                font-size:4em;
            }
        }
        img{
            width:80%;
            height:400px;
            object-fit:cover;
            z-index:0;
            position:absolute;
        }
    }
    
    `,
  
}

export default S