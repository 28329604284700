
import { Link } from 'react-router-dom';
import S from './style'
// import Image1 from '../../assets/sertification/tse.svg'
import Certification from '../../components/certification';
import { ReactComponent as Tse } from '../../assets/sertification/tsechoose.svg'
import { ReactComponent as Ce } from '../../assets/sertification/ce.svg'
import Iso from '../../assets/sertification/isopng.png'
import TranslateStatic from '../../locales/TranslateStatic';
import YumurtlamaImg from '../../assets/tibbi-genetik.jpg'
export function GenetikTarama() {
  return (
    <S.Wrapper>
      <S.Top>
        {/* <S.Header>
          <TranslateStatic string="aboutUsH2" />
        </S.Header> */}
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <Link><TranslateStatic string="genetik tarama" /></Link>
        </S.Span>
      </S.Top>
      {/* <S.Sertifika>
        <Certification />
      </S.Sertifika> */}
      <S.Section>
        <div className='banner'>
          <div className='baslik'>
            <h1>
              <TranslateStatic string="genetik tarama" />
            </h1>
            <h3>
            (GENETİK ve KALITSAL HASTALIKLARIN TANISI)
            </h3>
          </div>
          <div className='imgwrap'>

          <img className='foregroundImg' src={YumurtlamaImg} />
          <img className="backgroundImg" src={YumurtlamaImg} />
          </div>



        </div>

        <p>
          <TranslateStatic string="genetikTaramaUsP1" />
        </p>
        <h3><TranslateStatic string="genetikTaramaUsh3-1" /></h3>
        <p>
          <TranslateStatic string="genetikTaramaUsP2" />
        </p>
        <h3><TranslateStatic string="genetikTaramaUsh3-2" /></h3>
        <p>
          <TranslateStatic string="genetikTaramaUsP3" />
        </p>
        <h3><TranslateStatic string="genetikTaramaUsh4-1" /></h3>
        <ul>
          <li>
          <TranslateStatic string="genetikTaramaUsP4-1" />
          </li>
          <li>
          <TranslateStatic string="genetikTaramaUsP4-2" />
          </li>
          <li>
          <TranslateStatic string="genetikTaramaUsP4-3" />
          </li>
        </ul>
        <p>
          <TranslateStatic string="genetikTaramaUsP5" />
        </p>
        <p>
          <TranslateStatic string="genetikTaramaUsP6" />
        </p>
      </S.Section>
    </S.Wrapper >
  );
}
