import React from 'react';
import S from './style'
import Anne from '../../assets/anne.jpg'
import Hasta from '../../assets/hastaodakli.png'
import Ilgı from '../../assets/ilgi.png'
import Ulasilabilirlik from '../../assets/ulasilabilirlik.png'
import TranslateStatic from '../../locales/TranslateStatic'
function Whyus(props) {
    return (
        <S.Wrapper>
            <h4>
                Neden Biz?
            </h4>
            {/* <p>Üreme tıbbındaki gelişmeleri yakından takip ederek, 
            yeni ve doğruluğu test edilmiş tedavileri kliniğimizde sizlere sunuyoruz.</p> */}
            <p>
                Bilim ve teknolojiyi yakından takip eden ve katkı sağlayan deneyimli ve uzman ekibimizle kendinizi güvende hissedeceksiniz.
            </p>
            <S.ImageWrapper>
                <div className='contain'>
                    <img src={Hasta} />
                    <h3>Hasta Odaklı</h3>
                    <p>
                        Hasta haklarını savunan, ilgili, güler yüzlü, hasta odaklı ve kaliteli sağlık hizmetini hakediyorsunuz.
                    </p>
                </div>
                <div className='contain'>
                    <img src={Ulasilabilirlik} />
                    <h3>Ulaşılabilirlik</h3>
                    <p>
                    Tedaviniz boyunca hiç yalnız kalmayacaksınız; Doktorumuz ve tedavi koordinatörlerimize gün boyu ulaşabileceksiniz.
                    </p>
                </div>
                <div className='contain'>
                    <img src={Ilgı} />
                    <h3>İlgi</h3>
                    <p>
                    Size ayrılan yeterli vakitle kendinizi yeterince ifade edebilmenin mutluluğunu yaşayacaksınız.
                    </p>
                    </div>
            </S.ImageWrapper>
        </S.Wrapper>
    );
}

export default Whyus;