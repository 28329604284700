import React from 'react';
import { Link } from 'react-router-dom';
import S from './style'
import Logo from '../../assets/logo.svg'
import Insta from '../../assets/instagram.png'
import Whatsapp from '../../assets/whatsapp.png'
import TranslateStatic from '../../locales/TranslateStatic'
import Subscribe from '../subscribe';
function Footer(props) {
    return (
    <div>

        <Subscribe/>
        <S.Wrapper>
            <S.Memnun>
                <div className='main'>
                    {/* <Link>Memnuniyet Anketi</Link> */}
                    <S.Tex>
                        
                    <h3>Sizi arayalım</h3>
                    <p>
                    Öngörüşme ve danışmanlık için dilediğiniz zaman bize
                    ulaşabilir yada bizim size ulaşmamızı sağlayabilirsiniz.
                    </p>
                    </S.Tex>
                    <S.Email>
                        <input type='textbox' placeholder='İrtibat Numaranız'/>
                        <button>Gönder</button>
                    </S.Email>
                </div>
            </S.Memnun>
            <S.Ust>
                <div>
                    <S.Logo src={Logo} alt="dogantan logo" />
                    <p>Gelecek Tüp Bebek Merkezi ile siz de bilimin gücünü yanınıza alın.</p>
                    <div className='socialMedia'>
                        <a href='https://www.instagram.com/dogantanyangin/' target='_blank'>
                            <img src={Insta} />

                        </a>
                        <a href="whatsapp://send?phone=+905316425793">

                            <img src={Whatsapp} />
                        </a>
                    </div>
                </div>
                <div>
                    <h2><TranslateStatic string="contactH2" /></h2>
                    <ul>
                        <li><b><TranslateStatic string="contactAddress" /></b> : Çağlayan Mah. Bülent Ecevit Bulvarı No:167 Lara / <b>ANTALYA</b></li>
                        <li><b><TranslateStatic string="contactMail" /></b> : info@gelecektupbebek.com.tr </li>
                        <li><b><TranslateStatic string="contactPhone" /></b> : +90 (242) 324 25 26</li>
                    </ul>
                    <br></br>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12756.75049701164!2d30.6458726!3d36.9336835!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c38fa3bd97ee31%3A0x2c570f6c93b188d6!2sDo%C4%9Fan%20Tan%20Yang%C4%B1n%20ve%20G%C3%BCvenlik%20Sistemleri!5e0!3m2!1str!2str!4v1695849163927!5m2!1str!2str"
                        allowfullscreen="" loading="lazy">

                    </iframe>
                </div>
            </S.Ust>
            <div className="copyright">
                <span><TranslateStatic string="contactCopyright" /></span>
                <span><TranslateStatic string="contactWebAuthor" /> : <a href="https://ekipx.com/" target="_blank">ekipx.com</a></span>
            </div>
        </S.Wrapper>
                            </div>
    );
}

export default Footer;