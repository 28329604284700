import React from 'react'
import S from './style'
import Telephone from '../../assets/telephone.png'
import Hasta from '../../assets/hastaodakli.png'
import Ilgı from '../../assets/ilgi.png'
import Ulasilabilirlik from '../../assets/ulasilabilirlik.png'
import InstaColor from '../../assets/instagramColor.png'
import { InstagramEmbed } from 'react-social-media-embed';
export default function Subscribe() {
  return (
    <S.Wrapper>
      <div>
      <div className='top'>
        <h1>Takipte Kalın</h1>
        <p>Bilgilendirici videolar ve çok daha fazlası sosyal medya hesabımızda.</p>
        {/* <div className='circle'></div> */}
      </div>
      <S.ImageWrapper>
        <div className='contain'>
          <img src={InstaColor} />
          <div>

            <h3>@antalyagelecektupbebek</h3>
            <p>Instagram'dan Bizi Takip Edin.</p>
          </div>
        </div>


      </S.ImageWrapper>

      </div>
      <div style={{ display: 'flex', justifyContent: 'center', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px',height:'637px'}}>
        <InstagramEmbed url="https://www.instagram.com/p/C1EpI7QKzs-/" width={428} />
      </div>
    </S.Wrapper>
  )
}
