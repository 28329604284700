
import { Link } from 'react-router-dom';
import Slider from '../../components/slider'
import Product from '../../components/product';
import References from '../../components/references';
import Miniinfo from '../../components/miniinfo';
import Callmini from '../../components/callmini';
import S from './style';
import SliderV2 from '../../components/sliderv';
import FireExtin from '../../components/fireExtin';
import Whyus from '../../components/whyus';
import Treatments from '../../components/treatments';
export function HomePage() {
  return (
    <S.Wrapper>
      <S.SlideShow>
        <SliderV2 />
      </S.SlideShow>
      {/* <Callmini/> */}
      {/* <Product /> */}
      {/* <Miniinfo /> */}
      <Whyus/>
      <Treatments/>
      <FireExtin/>
      {/* <References /> */}
    </S.Wrapper>
  );
}
