import React from 'react'
import S from './style'
import Telephone from '../../assets/telephone.png'
import Hasta from '../../assets/hastaodakli.png'
import Ilgı from '../../assets/ilgi.png'
import Ulasilabilirlik from '../../assets/ulasilabilirlik.png'
export default function Treatments() {
  return (
    <S.Wrapper>
      <div className='top'>
        <h1>Tedavilerimiz</h1>
        <a href="tel:+90 (242) 324 25 26">Detaylı Bilgi Al<img className="tele" src={Telephone} /></a>
      <div className='circle'></div>
      </div>
      <S.ImageWrapper>
        <div className='contain'>
          {/* <img src={Hasta} /> */}
          <h3>Yumurtlamanın Uyarılması</h3>
        </div>
        <div className='contain'>
          {/* <img src={Ulasilabilirlik} /> */}
          <h3>Genetik Tarama</h3>
         
        </div>
        <div className='contain'>
          {/* <img src={Ilgı} /> */}
          <h3>Aşılama</h3>
        
        </div>
        <div className='contain'>
          {/* <img src={Hasta} /> */}
          <h3>Dondurarak Saklama</h3>
        
        </div>
        <div className='contain'>
          {/* <img src={Ulasilabilirlik} /> */}
          <h3>Endoskopik Ameliyatlar</h3>
        
        </div>
        <div className='contain'>
          {/* <img src={Ilgı} /> */}
          <h3>TESA / MIKRO TESE</h3>
        
        </div>
        <div className='contain'>
          {/* <img src={Ilgı} /> */}
          <h3>Tüp Bebek Tedavisi</h3>
         
        </div>
      </S.ImageWrapper>
    </S.Wrapper>
  )
}
