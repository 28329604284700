import React from 'react'
import { LangChangerSC } from './style'
import Translate from '../../../locales/TranslateStatic';
import { LocaleContext } from '../../../locale-context';
import TR from '../../../assets/turkey.png'
import EN from '../../../assets/united-kingdom.png'
export default function LanguagePicker(props) {

    const { changeLanguage } = props
    return (<LocaleContext.Consumer>
        {(value) => {
            if (value === "en") {
                return <LangChangerSC value={props.lang} onChange={changeLanguage}>
                    <option id="en" value="en"><img src={EN}/></option>
                    <option id="tr" value="tr"><img src={TR}/></option>
                </LangChangerSC>
            } else if (value === 'tr') {
                return <LangChangerSC value={props.lang} onChange={changeLanguage}>
                    {/* <option id="en" value="en">İNGİLİZCE</option>
                    <option id="cz" value="cz">ÇEKÇE</option>
                    <option id="ru" value="ru">RUSÇA</option>
                    <option id="de" value="de">ALMANCA</option>
                    <option id="tr" value="tr">TÜRKÇE</option> */}
                     <option id="en" value="en" >🇬🇧</option>
                    <option id="tr" value="tr" >🇹🇷</option>
                </LangChangerSC>
            }
            else if (value === 'ru') {
                return <LangChangerSC value={props.lang} onChange={changeLanguage}>
                    {/* <option id="en" value="en">АНГЛИЙСКИЙ</option>
                    <option id="cz" value="cz">ЧЕШСКИЙ ЯЗЫК</option>
                    <option id="ru" value="ru">РУССКИЙ</option>
                    <option id="de" value="de">НЕМЕЦКИЙ</option>
                    <option id="tr" value="tr">ТУРЕЦКИЙ</option> */}
                    <option id="en" value="en"><img src={EN}/></option>
                    <option id="tr" value="tr"><img src={TR}/></option>
                </LangChangerSC>
            }
            else if (value === 'de') {
                return <LangChangerSC value={props.lang} onChange={changeLanguage}>
                    {/* <option id="en" value="en">ENGLISCH</option>
                    <option id="cz" value="cz">TSCHECHISCH</option>
                    <option id="ru" value="ru">RUSSISCH</option>
                    <option id="de" value="de">DEUTSCHE</option>
                    <option id="tr" value="tr">TÜRKISCH</option> */}
                     <option id="en" value="en"><img src={EN}/></option>
                    <option id="tr" value="tr"><img src={TR}/></option>
                </LangChangerSC>
            }
            else if (value === 'cz') {
                return <LangChangerSC value={props.lang} onChange={changeLanguage}>
                    {/* <option id="en" value="en">ANGLIČTINA</option>
                    <option id="cz" value="cz">ČEŠTINA</option>
                    <option id="ru" value="ru">RUŠTINA</option>
                    <option id="de" value="de">NĚMEC</option>
                    <option id="tr" value="tr">TUREČTINA</option> */}
                    <option id="en" value="en"><img src={EN}/></option>
                    <option id="tr" value="tr"><img src={TR}/></option>
                </LangChangerSC>
            }
        }}
    </LocaleContext.Consumer>
    )



}