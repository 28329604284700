import React from "react";
import S from './style';
import Fire from '../../assets/fireExtin.png';
import FireV2 from '../../assets/hand.png';
import { ScrollRotate } from 'react-scroll-rotate';
export default function () {
    return <S.Wrapper>
        <S.Kare>
            <S.Abss>

                <ScrollRotate method={"perc"} from={40} to={1}>


                    <S.Object src={FireV2} />
                </ScrollRotate>
            </S.Abss>

        </S.Kare>
        <S.Sag>
            {/* <h1>4 YIL GARANTİLİ</h1> */}
            {/* <h2>
            Gelecek Tüp Bebek Merkezi ile siz de bilimin gücünü yanınıza alın.
            </h2> */}
            <p>
            Merkezimizde kendinizi <b>aile ortamında</b> hissedeceksiniz.
            </p>
            {/* <h2>Başlık 1</h2>
            <h3>Başlık 1 </h3>
            <h3>Başlık 1 </h3> */}
        </S.Sag>
    </S.Wrapper>
}