import React, { Component } from "react";
import Image1 from '../../assets/sliderImage/new/1.png'
import Image2 from '../../assets/sliderImage/new/2.png'
import Image3 from '../../assets/sliderImage/new/3.png'
import Image4 from '../../assets/sliderImage/new/4.jpg'
import Image5 from '../../assets/sliderImage/new/5.jpg'
import Image6 from '../../assets/sliderImage/new/6.jpg'
import Image7 from '../../assets/sliderImage/new/7.jpg'
import Image8 from '../../assets/sliderImage/new/8.jpg'
import Image9 from '../../assets/sliderImage/new/9.jpg'
import Image10 from '../../assets/sliderImage/new/10.jpg'
import Image11 from '../../assets/sliderImage/new/11.jpg'
import Image12 from '../../assets/sliderImage/new/12.jpg'
import Image13 from '../../assets/sliderImage/new/13.jpg'
import Telephone from '../../assets/telephone.png'
import Arrow from '../../assets/right-arrow.png'
import Video from '../../assets/video.mp4'
import S from './style'
class Carousel extends React.Component {
  constructor(props) {
    super(props);

    this.prevItem = this.prevItem.bind(this);
    this.nextItem = this.nextItem.bind(this);

    this.state = {
      counter: 0,
      timeout: setTimeout(this.nextItem, this.props.timeoutTime)
    };
  }

  prevItem() {
    var prevItem = this.state.counter - 1 < 0 ? this.props.items.length - 1 : this.state.counter - 1;

    clearTimeout(this.state.timeout);
    this.setState({
      counter: prevItem,
      timeout: setTimeout(this.nextItem, this.props.timeoutTime)
    });
  }

  nextItem() {
    var nextItem = this.state.counter + 1 < this.props.items.length ? this.state.counter + 1 : 0;

    clearTimeout(this.state.timeout);
    this.setState({
      counter: nextItem,
      timeout: setTimeout(this.nextItem, this.props.timeoutTime)
    });
  }

  render() {
    const items = this.renderItems(this.props.items);

    return (
      <S.carousel>
        {/* <S.carouselPrev onClick={this.prevItem}></S.carouselPrev> */}
        { items }
        {/* <S.carouselNext onClick={this.nextItem}></S.carouselNext> */}
      </S.carousel>
    );
  }

  renderItems(items) {
    return items.map((item, index) => this.renderItem(item, (this.state.counter === index)));
  }

  renderItem(item, current) {
    return (
      <CarouselItem {...item} current={current}></CarouselItem>
    );
  }
}

class CarouselItem extends React.Component {
  render() {    
    return (
      <S.carouselItem className={(this.props.current ? 'current' : '')}>
        {/* <img src={this.props.image} role="presentation" /> */}
        <video src={Video} autoPlay muted loop={true} role="presentation"/>
        <S.carouselHeading className={(this.props.current ? '' : 'show')}>
          <div className="left">

          <h2>{this.props.title}</h2>
          <p>{this.props.caption}</p>
          <S.RandevuWrap>
            <a href="tel:+90 (242) 324 25 26">Randevu Al<img className="tele" src={Telephone}/></a>
            <a target="_blank" href="https://www.google.com/maps?ll=36.860364,30.779435&z=16&t=m&hl=tr&gl=US&mapclient=embed&cid=17978173856013657874">Konum Bilgisi <img className="tele" src={Arrow}/></a>
          </S.RandevuWrap>
          </div>
          <div className="right">
            Yaklaşan etkinlikler kısmı için duyuru bölümü
          </div>
        </S.carouselHeading>
      </S.carouselItem>
    );
  }
}

const carouselData = {
      timeoutTime: 5000,
      items: [
        // {
        //   key: 1,
        //   image: Image1,
        //   title: 'Slide #1',
        //   caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        // },
        // {
        //   key: 2,
        //   image: Image2,
        //   title: 'Slide #2',
        //   caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        // },
        // {
        //   key: 3,
        //   image: Image3,
        //   title: 'Slide #3',
        //   caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        // },
        {
          key: 4,
          image: Video,
          title: 'Gelecek Tüp Bebek Merkezi ile siz de bilimin gücünü yanınıza alın.',
          caption: 'Bundan 30 yıl önce tıpta bir mucize gerçekleşti ve "ilk tüp bebek" dünyaya geldi. Zaman içinde tüp bebek teknolojisinde yaşanan gelişmeler başarı şansını sürekli artırırken, tedavi sürelerini de kısalttı. Bu şekilde binlerce çift daha konforlu bir tedaviyle hayallerine kavuştu.'
        },
        // {
        //   key: 5,
        //   image: Image5,
        //   title: 'Yangın Teklikesine Karşı Kapsamlı Koruma Sunuyoruz',
        //   caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        // },
        // {
        //   key: 6,
        //   image: Image6,
        //   title: 'Yangın Güvenliği İçin Bilinçli ve Hazır Olun',
        //   caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        // },
        // {
        //   key: 7,
        //   image: Image7,
        //   title: 'Yangın Risklerini Belirlemek ve Çözüm Sunmak İçin Yanınızdayız',
        //   caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        // },
        // {
        //   key: 8,
        //   image: Image8,
        //   title: 'Yangın Güvenliğinizi Bizimle Güvence Altına Alın',
        //   caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        // },
        // {
        //   key: 9,
        //   image: Image9,
        //   title: 'Profesyonel Yangın Alarm Cihazları ve Bakım Hizmetleri',
        //   caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        // },
        // {
        //   key: 10,
        //   image: Image10,
        //   title: 'Yangın Güvenliği İçin Ekipman Kontrolleri ve Bakımı',
        //   caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        // },
        // {
        //   key: 11,
        //   image: Image11,
        //   title: 'Yangınla Mücadelede Her Zaman Hazırız',
        //   caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        // },
        // {
        //   key: 12,
        //   image: Image12,
        //   title: 'Profesyonel Yangın Söndürme Cihazı Denetimi ve Bakım Hizmetleri',
        //   caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        // },
        // {
        //   key: 13,
        //   image: Image13,
        //   title: '',
        //   caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        // }
      ]
    };

function slid (){

  return <Carousel {...carouselData} />
}

export default slid