import React from 'react';
import S from './style'
import Anne from '../../assets/anne.jpg'
import Hasta from '../../assets/hastaodakli.png'
import Ilgı from '../../assets/ilgi.png'
import Ulasilabilirlik from '../../assets/ulasilabilirlik.png'
import TranslateStatic from '../../locales/TranslateStatic'
import Whatsappicon from '../../assets/whatsappicon.png'
function Whatsup(props) {
    return (
        <S.Wrapper>
            <S.ImageWrapper>
                <a target='_blank' href="https://wa.me/+905334877197"><img src={Whatsappicon} /></a>
            </S.ImageWrapper>
        </S.Wrapper>
    );
}

export default Whatsup;