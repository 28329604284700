import React, { useState, useEffect } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import { HomePage } from './pages/Home';
import { PageTwo } from './pages/Page2';
import { Product } from './pages/Product';
import { Services } from './pages/Services';

import { Iletisim } from './pages/ContactUs';

import { Reference } from './pages/References';
import { Category } from './pages/Category';
import { SubCategory } from './pages/SubCategory';
import { Cookie } from './pages/Cookie';
import { Cer } from './pages/Certification';
import { Catalog } from './pages/catalog';
import { Teklif } from './pages/teklif';

import Header from './components/header'
import Footer from './components/footer';
import landChecker from './utils/langChecker';
import {Team} from './pages/Team';
import {Yumurtlama} from './pages/Yumurtlama';
import {GenetikTarama} from './pages/GenetikTarama';
import {Asilama} from './pages/Asilama';
import {DondurarakSaklama} from './pages/DondurarakSaklama';
import {Endoskopik} from './pages/Endoskopik';
import {Tesa} from './pages/Tesa';
import {TupBebek} from './pages/TupBebek';


export default () => {


   return < Routes >
        <Route path="/" element={<Layout />}>
            <Route path="/" index element={<HomePage/>} />
            <Route path="/aboutus" element={<PageTwo/>} />
            <Route path="/yumurtlama" element={<Yumurtlama/>} />
            <Route path="/genetiktarama" element={<GenetikTarama/>} />
            <Route path="/asilama" element={<Asilama/>} />
            <Route path="/donduraraksaklama" element={<DondurarakSaklama/>} />
            <Route path="/endoskopikameliyatlar" element={<Endoskopik/>} />
            <Route path="/tesa" element={<Tesa/>} />
            <Route path="/tupbebek" element={<TupBebek/>} />
            <Route path="/services" element={<Services/>} />
            <Route path="/product/:catid/:subcatid/:link" element={<Product/>} />
            <Route path="/references" element={<Reference/>} />
            <Route path="/contactus" element={<Iletisim/>} />
            <Route path="/offer" element={<Teklif/>} />
            <Route path="/catalog" element={<Catalog/>} />
            <Route path="/category" element={<Category/>} />
            <Route path="/cookie" element={<Cookie/>} />/
            <Route path="/team" element={<Team/>} />/
            <Route path="/category/:name/:id/:index" element={<SubCategory/>} />
        </Route>
    </Routes >

};

function Layout() {
    const [preferredLocale, setPreferredLocale] = useState("tr");
    function changeLanguage(event) {
        setPreferredLocale(event.target.value)
    };
    useEffect(() => {
        setPreferredLocale(landChecker);
    }, []);
    return (<>
        <Header lang={preferredLocale} changeLanguage={changeLanguage} />
        <Outlet />
        <Footer />
    </>
    );
}