import S from './style'
import BANNERIMG from '../../../assets/IMG_2460.jpeg'
export function Banner(){
    return(
        <S.Wrapper>
            <div className='imgbanner'>
            <div className='Gradient'>
                <h1>
                    Bilimsel <br/>Toplantılarımız
                </h1>
            </div>
            <img src={BANNERIMG}/>
            </div>
        </S.Wrapper>
    )
}